import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
  production: false,
  api: 'http://localhost:3000/',
  // graphqlHost: 'http://localhost:3000/',
  auditGraphqlHost: 'https://scebe-dev01.azurewebsites.net/graphql',
  newAuditGraphqlHost: 'https://scecp-auditservice-dev.dnv.com/graphql',
  graphqlHost: 'https://scebe-dev-api2.azurewebsites.net/graphql',
  findingGraphqlHost: 'https://scebefindings-dev.azurewebsites.net/graphql',
  certificateGraphqlHost:
    'https://sce-certservice-dev.azurewebsites.net/graphql',
  newCertificateGraphqlHost:
    'https://scecp-certificateservice-dev.dnv.com/graphql',
  scheduleGraphqlHost:
    'https://sce-scheduleservice-dev.azurewebsites.net/graphql',
  contractsGraphqlHost:
    'https://sce-contractsservice-dev.azurewebsites.net/graphql',
  invoicesGraphqlHost:
    'https://sce-invoiceservice-dev.azurewebsites.net/graphql',
  powerBi: {
    accessToken: '',
    reportId: '4e19ccf9-1ff9-4a04-bd2d-1bab9cb1c029',
    embedUrl:
      'https://app.powerbi.com/reportEmbed?reportId=4e19ccf9-1ff9-4a04-bd2d-1bab9cb1c029&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1FLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmVwb3J0RW1iZWQiOnRydWV9fQ%3d%3d',
  },
  appInsights: {
    instrumentationKey: 'b575ce6f-5554-4552-90fc-732434fa7568',
  },
  documentsApi: 'https://scedocservice-dev.azurewebsites.net/api/Documents',
};
